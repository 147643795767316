import { AppEnvironment } from 'features/app/config/configType';

export enum Flight {
	//#region A
	/**
	 * Increases number of customer search results from 10 to 50
	 *
	 * Owner: Cassandra
	 * Good Until: 5/31/2024
	 */
	accountExtensionsOrgSearchMoreResults = 'accountExtensionsOrgSearchMoreResults',
	/**
	 * New start date/end date picker requirements for Direct ACO
	 *
	 * Owner: Mariam
	 * Good Until: Unknown
	 */
	acoStartEndDate = 'acoStartEndDate',
	/**
	 * Expanded display for seats constraints information
	 *
	 * Owner/Requester: Harry
	 * Good Until: Unknown
	 */
	addOnMaxPurchaseInfo = 'addOnMaxPurchaseInfo',
	/**
	 * AirCapi Cloud
	 *
	 * Owner/Requester: Jason
	 * Good Until: PERMANENT - Aircapi is for test environments only
	 */
	airCapi = 'aircapi',
	/**
	 * Allows the users to pick specific end date(only month) for Azure Commitment Discount product
	 * Owner/Requester: Can/Tiffany
	 * Good Until: Unknown
	 */
	allowEndDateWithoutStartDateForAzureCommitmentDiscount = 'allowEndDateWithoutStartDateForAzureCommitmentDiscount',
	/**
	 * Owner/Requester: Can
	 * Good Until: unknown
	 */
	alwaysLoadV12QuoteInSagas = 'alwaysLoadV12QuoteInSagas',
	/**
	 * Allows user to select skus with unspecified locations in production.
	 *
	 * Owner/Requester: Julie
	 * Good Until: Unknown
	 */
	allowUnspecifiedOptionInLocationFilters = 'allowunspecifiedoptioninlocationfilters',
	/**
	 * Allows to auto-approve quote after been submitted.
	 *
	 * Owner/Requester: Julie
	 * Good Until: PERMANENT - For Testing/Demo Only
	 */
	approvalOverride = 'approvaloverride',
	/**
	 * Properly filters events for latestPurchaseRecordId in GQL
	 *
	 * Owner/Requester: jejungk
	 * Good Until: Jan 2025
	 */
	assetEvents = 'assetEvents',
	/**
	 * Uses filters when calling CommerceCache API for Assets
	 *
	 * Owner/Requester: jejungk
	 * Good Until: Nov 2024
	 */
	assetFilters = 'assetFilters',
	/**
	 * Will introduce new UX changes for Asset Panel - Subscription Management
	 *
	 * Owner/Requester: Pallavi
	 * Good Until: A month after release.
	 */
	assetPanelUxChanges = 'assetPanelUxChanges',
	/**
	 * Will introduce FlyInPanel experience for Azure Support
	 *
	 * Owner/Requester: Dixya
	 * Good Until: Unknown
	 */
	azureSupportConfigFlyInPanel = 'azureSupportConfigFlyInPanel',
	//#endregion

	//#region B
	/**
	 * Enables customer Billing Profiles to scope all discounts
	 *
	 * Owner/Requester: Mrinal
	 * Good Until: A month after release - TBD
	 */
	billingProfileForDiscounts = 'billingProfileForDiscounts',
	/**
	 * Enables breadcrumbs in the Navigation Bar
	 *
	 * Owner/Requester: Jennie
	 * Good Until: A month after release - 09/01 (with FedGov)
	 */
	breadcrumbs = 'breadcrumbs',
	/**
	 * Allows bulk approval of list of quote ids.
	 *
	 * Owner/Requester: Can/Mark
	 * Good Until: Permanent - Issue in prod
	 */
	bulkApproveTransact = 'bulkapprovetransact',
	//#endregion

	//#region C
	/**
	 * Allows to cancel Assets of quote ids.
	 *
	 * Owner/Requester: Vijay/Julie
	 * Good Until: Short time
	 */
	canCancelAsset = 'canCancelAsset',
	/**
	 * Enables a button in the quote summary that duplicates a proposal
	 *
	 * Owner/Requestor: Jill/Natalia/Mariam
	 * Good Until: 06/23
	 */
	cloneProposal = 'cloneProposal',
	/**
	 * Convert dates to ISO String when doing bulk editing
	 * Owner/Requester: Can
	 * Good Until: Month after prod release
	 */
	convertDatesToISOStringOnBulkDiscount = 'convertDatesToISOStringOnBulkDiscount',
	/**
	 * Add Commerce Insights to converged workspace experience
	 *
	 * Owner/Requester: Jennie
	 * Good Until: unknown
	 */
	convergence = 'convergence',
	/**
	 * End date alignment with line item from proposal
	 *
	 * Owner/Requester: Harry/Tiffany
	 * Good Until: Month after prod release
	 */
	cotermWithLineItemFromProposal = 'cotermWithLineItemFromProposal',
	/**
	 * End date alignment with line item from proposal using mock data
	 *
	 * Owner/Requester: Harry/Tiffany
	 * Good Until: Month after prod release
	 */
	cotermWithLineItemFromProposalMockData = 'cotermWithLineItemFromProposalMockData',
	/**
	 * Market currency override
	 *
	 * Owner/Requester: Jason Soden
	 * Good Until: PERMANENT
	 */
	currencyOverride = 'currencyOverride',
	/**
	 * Enables CWA to be used by org id or tenant id instead of tpid
	 * TEST ONLY, should never be on by default
	 *
	 * Owner/Requester: stmill
	 * Good Until: PERMANENT - TEST ONLY
	 */
	cwaByModernIds = 'cwaByModernIds',
	/**
	 * enables CWA to use Benefits RP instead of balance service to display Contributor list for Primary BA if exists
	 *
	 * Owner/Requester: kasub/paul
	 * Good Until: unknown for now.
	 */
	cwaMultiMaccContriEntity = 'cwaMultiMaccContriEntity',
	/**
	 * Show planned accounts in Billing Accounts section
	 *
	 * Owner/Requester: simranpujji
	 * Good Until: after transition map release
	 */
	cwaPlannedAccounts = 'cwaPlannedAccounts',
	/**
	 * enables zero to be entered for macc shortfall post-paid invoices.
	 *
	 * Owner/Requester: stmill
	 * Good Until: zero-dollar post-paid MACC shortfalls are supported by downstream systems
	 */
	cwaShortfallPostPaidAllowZero = 'cwaShortfallPostPaidAllowZero',
	/**
	 * Flight changes to shortfall panel
	 *
	 * Owner/Requester: simranpujji
	 * Good until: unknown
	 */
	cwaShortfallPanelChanges = 'cwaShortfallPanelChanges',
	/**
	 * Checks to see if tenants have a valid id for the add customer flow
	 *
	 * Owner/Requester: nicoscalzo
	 * Good until: unknown
	 */
	cwaTenantAccountCheck = 'cwaTenantAccountCheck',
	//#endregion

	//#region D

	/**
	 * Allows us to see Approved benefits and benefits on quote separately
	 *
	 * Owner/Requester: Jennie
	 * Good Until: A month after release - 09/27
	 */
	dealTotalityBenefitsOnQuote = 'dealTotalityBenefitsOnQuote',
	/**
	 * Enables publish-blocking messages to check if the partner or customer's tenant still exists.
	 * Owner/Requester: Steven
	 * Good Until: the feature is good and tested!
	 */
	deletedTenantCheck = 'deletedTenantCheck',
	/**
	 * Owner/Requester: Jason/Karina
	 * Good Until: Permanent - Test only
	 */
	demoMode = 'demomode',
	/**
	 * Owner/Requester: Can
	 * Good Until: Unknown
	 */
	deduplicateProductIdsOnMultiSelect = 'deduplicateProductIdsOnMultiSelect',
	/**
	 * Owner/Requester: Harry
	 * Good Until: Unknown
	 */
	delaySearchByMeterId = 'delaySearchByMeterId',
	/**
	 * Enable SignUp APIs from GraphQL instead of 3PA through QCS
	 *
	 * Owner/Requester: Mariam/ V-mparthasar
	 * Good Until: Unknown
	 */
	/**
	 * Allows direct commercial quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	directComNonDFD = 'directcomnondfd',
	/**
	 * Allows selection of fgoe on a direct-goverment quote
	 *
	 * Owner/Requester: Jason
	 * Good Until: A month after release - 10/01
	 */
	directFGOE = 'directfgoe',
	/**
	 * Allows direct-gov quotes to be created
	 *
	 * Owner/Requester: Jason
	 * Good Until: A month after release - 10/01
	 */
	directGov = 'directgov',
	/**
	 * Allows direct-gov quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	directGovNonDFD = 'directgovnondfd',
	/**
	 * Disable bulk edit for Passes product
	 *
	 * Owner/Requester: Harry
	 * Good Until: Unknown
	 */
	disableBulkEditForPasses = 'disableBulkEditForPasses',
	/**
	 * Allows to search for Office products
	 *
	 * Owner/Requester: Julie
	 * Good Until: PERMANENT - TEST ONLY
	 */
	disableProductInclusionFilter = 'disableproductinclusionfilter',
	/**
	 * Owner/Requester: Can
	 * Disable Epics and Sagas
	 * Good Until: We verify that we do not need these
	 */
	disableSagasAndEpics = 'disableSagasAndEpics',
	/**
	 * Do not show test term recommendations
	 *
	 * Owner/Requester: Can/Julie
	 * Good Until: PERMANENT - TEST ONLY
	 */
	disableTestTermRecommendations = 'disabletesttermrecommendations',
	/**
	 * Enables you to see the changing etag in the UX for debugging purposes
	 *
	 * Owner/Requester: Can
	 * Good Until:PERMANENT - TEST ONLY
	 */
	displayEtagsForDebugging = 'displayEtagsForDebugging',
	/**
	 * Shows test MCAs in Commerce Insights
	 * TEST ONLY, should never be on by default
	 *
	 * Owner/Requester: Jennie
	 * Good Until: PERMANENT - TEST ONLY
	 */
	displayTestMCAs = 'displaytestmcas',
	/**
	 * Owner/Requester: Can
	 * Good Until: Unknown
	 */
	doNotUpdateLineItemRecipientAndAssetTo = 'doNotUpdateLineItemRecipientAndAssetTo',
	/**
	 * Owner/Requester: Pallavi/Tiffany
	 * Good Until: TBD
	 * Enables the new download PDF enhanced UX experience for passes product lineItems
	 */
	downloadPDFConvertChanges = 'downloadPDFConvertChanges',
	//#endregion

	//#region E
	/**
	 * Enables the use of E2 Fragments instead of the ones implemented in this codebase, this also enables all of the other features needed to make those Fragments work.
	 *
	 * Owner: Jason O'Neill
	 * Good Until: Initial E2 fragments has been stable in production for a while
	 */
	e2Fragments = 'e2Fragments',
	/**
	 * Enables the use of the @e2/router library to replace the one built-in from react-router. This is necessary for compatibility with E2 Fragments.
	 *
	 * Owner: Jason O'Neill
	 * Good Until: Router changes have been tested in production and MSX.
	 */
	e2Router = 'e2Router',
	/**
	 * Enables Apollo Request Inspector
	 * When turned off, you would not get any call headers in the response.
	 * Owner/Requester: Can
	 * Release date: Same PR as the creation of this flight.
	 */
	enableApolloRequestInspector = 'enableApolloRequestInspector',
	/**
	 * Enables Apollo Request Inspector experimental client filter options
	 * Owner/Requester: Can
	 * Release date: Same PR as the creation of this flight.
	 */
	enableApolloRequestInspectorClientFilters = 'enableApolloRequestInspectorClientFilters',
	/**
	 * use grid for labels in details pane
	 *
	 * Owner/Requester: Can
	 * Release date: When the work to migrate non-grid parts to grid is done
	 */
	enableDetailsPaneLabelGrid = 'enabledetailspanelabelgrid',
	/**
	 * enable new lineItem type for meters lineItems
	 *
	 * Owner/Requester: Hongyu
	 * Release date: Unknown
	 */
	enableMetersLineItem = 'enableMetersLineItem',
	/**
	 * Enable service desk attachment functionality
	 * Owner/Requester: Can
	 * Release date: After the new service desk 1PA attachment functionality is ready
	 */
	enableServiceDeskAttachment = 'enableServiceDeskAttachment',
	/**
	 * Enable new stamp config v2
	 *
	 * Owner/Requester: Niti
	 * Release date: Unknown
	 */
	enableStampConfigV2 = 'enableStampConfigV2',

	/**
	 * DD360 QCS migration enable endpoint to 1P
	 * Good Until: Unknown
	 */
	enableQCSMigrationwith1PADD360 = 'enableQCSMigrationwith1PADD360',

	/**
	 * Uses to enabl and disable BD360 attachment using graphQl(QCS migration)
	 * Good Until: Unknown
	 */
	enableDD360AttachmentGQL = 'enableDD360AttachmentGQL',

	/**
	 * Uses a quicker search method for shared meters
	 * Owner/Requester: Can
	 */
	enableQuickLookupForSharedMeters = 'enablequicklookupforsharedmeters',
	/**
	 * Owner/Requester: Team consensus in standup
	 * Good Until: PERMANENT - TEST ONLY
	 */
	enableReferralCloseButtonForTesting = 'enableReferralCloseButtonForTesting',
	/**
	 * Owner/Requester:Product Team
	 * Good Until: 2024-07
	 * Leap Year and all endDates alignment
	 */
	endDateAdjustment = 'endDateAdjustment',
	/**
	 * Change text to reflect ES for Azure are graduated
	 *
	 * Owner/Requester: Harry/Tiffany
	 * Good Until: Month after prod release
	 */
	esGraduatedPricingOnlyForAzure = 'esGraduatedPricingOnlyForAzure',
	/**
	 * Enable ESTS-R on the backend auth flow
	 *
	 * Owner/Requester: Eliud
	 * Good Until: Unknown
	 */

	estsrRegionAutodetect = 'estsrRegionAutodetect',
	/**
	 * Shows Etag mismatch dialog when an etag error occurs.
	 *
	 * Owner/Requester: annaalla
	 * Good Until: Unknown
	 */
	excludeSavingsPlan = 'excludeSavingsPlan',
	//#endregion

	//#region F
	/**
	 * Only display eligible Billing Account Owners when selecting billing account owner
	 *
	 * Owner/Requester: Jennie
	 * Good Until: Until it is not needed
	 */
	filterForBillingAccountOwners = 'filterforbillingaccountowners',
	/**
	 * Only show products that have SKU and availability
	 *
	 * Owner/Requester: Hongyu
	 * Good Until: One month after deployment
	 */
	filterNoSkuNoAvailabilityProducts = 'filterNoSkuNoAvailabilityProducts',
	/**
	 * Forces future discount options for products using SubscriptionProductConfigurationPanel
	 *
	 * Owner/Requester: jejungk
	 * Good Until: PERMANENT - TEST ONLY
	 */
	forceFutureDiscountOptions = 'forceFutureDiscountOptions',
	/**
	 * Owner/Requester: Can
	 * Good Until: After I verify this in test
	 */
	fixAccidentalShowingOfAllDiscountsAddedInSharedDiscountPanel = 'fixAccidentalShowingOfAllDiscountsAddedInSharedDiscountPanel',
	//#endregion

	//#region G
	/**
	 * Owner/Requester: Vijay/Shahnaz
	 * Good Until: Test only
	 */
	graphAuthorizationUsingQCApp = 'graphAuthorizationUsingQCApp',
	/**
	 * Owner/Requester: Vijay/Shahnaz
	 * Good Until: Test only
	 */
	graphToCreateSPNUsingQCApp = 'graphToCreateSPNUsingQCApp',
	/**
	 * Flight to deprecate MSGraph (replacing removalOfCrossCloud flight)
	 *
	 * Owner/Requester: simranpujji
	 * Good Until: Unknown
	 */
	graphDepPublicTenantValidation = 'graphDepPublicTenantValidation',
	/**
	 * Enables to retrieve MCAPI footprint for legacy enrollment using GSM API
	 *
	 * Owner/Requester: Nico Scalzo
	 * Good Until: Unknown
	 */
	gsmProject = 'gsmProject',
	/**
	 * Enables guided tour for quote center
	 *
	 * Owner/Requester: Nanor
	 * Good Until: Unknown
	 */
	guidedTour = 'guidedTour',
	//#endregion

	//#region H
	/**
	 * Do not display Affiliat for Federal Government Direct or Indirect on the Organization Wizard
	 * Owner/Requester: nicoscalzo
	 * Good Until: Unknown

	 */
	hideAffiliateFedGov = 'hideAffiliateFedGov',
	/**
	 * Hides option to download shared meters
	 *
	 * Owner/Requestor: Jenn/Julie
	 * Good Until: TBD
	 */
	hideSharedMeters = 'hideSharedMeters',
	/**
	 * Hides Product Config Details for unconfigured products
	 * Good Until: TBD
	 */
	hideUnconfiguredProductConfigDetails = 'hideUnconfiguredProductConfigDetails',
	//#endregion

	//#region I
	/**
	 * There are some quotes that are returned in search index but they actually do not exist, the search index is out of sync;
	 * this enables to return search results on quotes with ignoring those ones
	 *
	 * Owner/Requestor:Can
	 * Good Until: PERMANENT
	 */
	ignoreNonExistingQuotesOnSearchIndex = 'ignoreNonExistingQuotesOnSearchIndex',
	/**
	 * Ignore test email validation in test environment
	 *
	 * Owner/Requestor:Megan/Can
	 * Good Until : PERMANENT - TEST ONLY
	 */
	ignoreTestEmailValidation = 'ignoretestemailvalidation',
	/**
	 * Allows indirect commercial for china quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	indirectComChinaNonDFD = 'indirectcomchinanondfd',
	/**
	 * Allows indirect commercial for CSP quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	indirectComCSPNonDFD = 'indirectcomcspnondfd',
	/**
	 * Allows indirect-gov quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	indirectGovNonDFD = 'indirectgovnondfd',
	/**
	 * Changes retry code for indirect partner customer footprint
	 *
	 * Owner/Requester: nicoscalzo
	 * Good Until: unknown
	 */
	indirectPartnerCustomerFootprintRetry = 'indirectPartnerCustomerFootprintRetry',
	/**
	 * Add Flight for HRDD Doc Hold for Testing
	 *
	 * Owner/Requester: Sita/Mohanraj
	 * Good Until: Permanent - Test only
	 */
	enableHRDDDocHold = 'enableHRDDDocHold',
	//#endregion

	//#region J
	/**
	 * Allow Jamaica for Direct quotes
	 *
	 * Owner/Requester: Jason Soden
	 * Good Until: A month after release - 6/1
	 */
	//#endregion

	//#region K
	//#endregion

	//#region L
	/**
	 * Uses QS v13 to support Large Quotes
	 *
	 * Owner/Requester: jejungk/titung
	 * Good Until: March 2025
	 */
	largeQuote1 = 'largeQuote1',
	/**
	 * Load product filters after other lineItems details load
	 *
	 * Owner/Requestor: Mariam
	 * Good Until: TBD
	 */
	lazyLoadProductFilters = 'lazyLoadProductFilters',
	/**
	 * Allows legacy quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	legacyNonDFD = 'legacynondfd',
	/**
	 * Organization Creation/Search should be limited to the market of the quote
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	limitBillingAccountByMarket = 'limitBillingAccountByMarket',
	/**
	 * Owner/Requester: Jason Soden
	 * Good Until: PERMANENT
	 */
	/**
	 * Owner/Requester: Dixya
	 * Good Until: unknown
	 */
	lineItemLimitIncrease = 'lineitemlimitincrease',
	/**
	 * Owner/Requester: Hongyu
	 * Good Until: 1 month after release
	 */
	loadMinimumRequiredPagesForSharedDiscount = 'loadMinimumRequiredPagesForSharedDiscount',
	//#endregion

	//#region M
	/**
	 * Owner/Requester: Cassandra Ho
	 * Good Until: CWA MACC goes live
	 */
	maccModifyCancelPanelCWA = 'maccModifyCancelPanelCWA',
	/**
	 * allows Malaysia for direct Quotes
	 *
	 * Owner/Requester: Jason Soden
	 * Good Until: 4 weeks after release - 1/1/22
	 */
	/**
	 * Shows maximum SKU limit reached line item error message returned from Validate API
	 *
	 * Owner/Requester: Manisha Sivaiah/Julie Ginesi
	 * Good Until: 9/20/2023
	 */
	maximumSkuLimitReachedMessage = 'maximumSkuLimitReachedMessage',
	/**
	 *  Measure shared meter performance
	 *
	 * Owner/Requester: Can
	 * Good Until: Test Flight so after we done the testing
	 */
	measureSharedMetersPerformance = 'measureSharedMetersPerformance',
	/**
	 * Owner/Requester: Can
	 * Good Until: 8/10/2024
	 */
	multiSelectTeachingBubble = 'multiSelectTeachingBubble',
	/**
	 * Enforce authorization on mutations
	 *
	 * Owner/Requester: Eliud
	 * Good Until: Unknown
	 */
	mutationAuth = 'mutationAuth',
	//#endregion

	//#region N
	/**
	 * Enables the new authentication flow
	 *
	 * Owner/Requestor: Eliud
	 * Good Until: Unknown
	 */
	newAuthFlow = 'newAuthFlow',
	/**
	 * Allows testing with no test headers
	 * DO NOT TURN ON!!!!
	 *
	 * Owner/Requester: Mark
	 * Good Until: PERMANENT - TEST ONLY
	 */
	noTestHeaderTesting = 'notestheadertesting',
	/**
	 * Allows non standard framework quotes to use nonDFD flow
	 *
	 * Owner/Requester: Nanor / Pallavi / Julie
	 * Good Until: testing complete
	 */
	nonStandardFrameworkNonDFD = 'nonstandardframeworknondfd',
	//#endregion

	//#region O
	/**
	 * Identifies translated strings where translation is not available.
	 *
	 * Owner/Requester: Ozgun
	 * Good Until: PERMANENT - TEST ONLY
	 */
	obfuscateTranslations = 'obfuscatetranslations',

	//#region O
	/**
	 * New approach allows to create offline agreement and patch the participants without deleting and creating a new agreement.
	 *
	 * Owner/Requester: vchayammagar
	 * Good Until: TBD
	 */
	offlineAgreementNewFlow = 'offlineAgreementNewFlow',
	//#endregion

	//#region P
	/**
	 *  Various small perf enhancements
	 *
	 * Owner/Requestor: Mariam
	 * Good Until: TBD
	 */
	/**
	 * Allows Physical Signature for UK Framework
	 *
	 * Owner/Requester: Neha
	 * Good Until: A month after release - 05/01
	 */
	physicalSignForUkccf = 'physicalSignForUkccf',
	/**
	 * Enables search for a product based on just the PID
	 *
	 * Owner/Requester: iabowers
	 * Good Until: PERMANENT - TEST ONLY - this is now on in Prod, is still Permanent?
	 */
	pidSearch = 'pidsearch',
	/**
	 * Enables Pricelist feature
	 *
	 * Owner/Requester: Manisha/Natalia
	 * Good Until: 12/5
	 */
	pricelist = 'pricelist',
	/**
	 * Enables Cart across pricelist ability
	 *
	 * Owner/Requester: Manisha/Natalia
	 * Good Until: 12/5
	 */
	pricelistCartAcross = 'pricelistCartAcross',
	/**
	 * Enables Cart across pricelist ability
	 *
	 * Owner/Requester: Manisha/Natalia
	 * Good Until: 12/5
	 */
	pricelistExpandedView = 'pricelistExpandedView',
	/**
	 * Aligns Catalog Claim filters to valid QC Quote Context combinations
	 *
	 * Owner/Requester: Manisha/Natalia
	 * Good Until: 12/5
	 */
	pricelistQuoteFilters = 'pricelistQuoteFilters',
	/**
	 * DEV ONLY
	 * Enables Product Inclusion Checker Panel where devs and Pm's can check the details about if a product is included in QC.
	 * Owner/Requester: Can
	 * Good Until: Unknown
	 *
	 */
	productInclusionCheckerPanel = 'productInclusionCheckerPanel',
	/**
	 * Increases number of product search results pages from 3 to 6
	 *
	 * Owner/Requester: Hongyu
	 * Good Until: A month after release
	 */
	productSearchMoreResults = 'productSearchMoreResults',
	/**
	 * Prevents productSearch to show duplicate products in gov context when switching nationalCloud tabs
	 *
	 * Owner/Requester: Nanor
	 * Good Until: turned on in prod
	 */
	productSearchRemoveDuplicateResult = 'productSearchRemoveDuplicateResult',
	/**
	 * Bug Fix for Promotion Panel to handle multiple promotions
	 *
	 * Owner/Requester: Pallavi Sathe
	 * Good Until: Unknown
	 */
	promotionProductOwnerShipFix = 'promotionProductOwnerShipFix',
	/**
	 * Enable to display Proposal steps for all Non21v quotes.
	 *
	 * Owner/Requester: Navya Jeevan/Natalie/Steve
	 * Good Until: TBD
	 */
	proposalSteps = 'proposalSteps',
	//#endregion

	//#region Q
	/**
	 * MSX Portal
	 *
	 * Owner/Requester: Sowmya/Jason Schneider
	 * Good Until: Permanent
	 */
	qcInMSX = 'qcinmsx',
	/**
	 * MCAPI Policy
	 */
	mcapiPolicy = 'mcapiPolicy',
	/**
	 * Enable QCS Migration Service Desk Attachment 1PA
	 * Owner/Requester: Can/ Natalia
	 */
	qcsMigrationServiceDeskAttachment1PA = 'qcsMigrationServiceDeskAttachment1PA',
	/**
	 * Signals QS to enforce the use of ELM roles during requests
	 *
	 * Owner/Requester: Eliud
	 * Good Until: Unknown
	 */
	qsElmEnforce = 'qsElmEnforce',
	/**
	 * GQL does not process lineItems for messages
	 *
	 * Owner/Requester: jejungk
	 * Good Until: Nov 2024
	 */
	quoteHeaderMessages = 'quoteHeaderMessages',
	/**
	 * Adding new features for MCA-E Renewals work
	 *
	 * Owner/Requester: simranpujji
	 * Good Until: unknown
	 */
	quoteRenewals = 'quoteRenewals',
	/**
	 * Show subscription data from billingRP instead of CommerceCache
	 *
	 * Owner/Requester: simranpujji
	 * Good Until: unknown
	 */
	quoteRenewalsBillingRP = 'quoteRenewalsBillingRP',
	/**
	 * Use mock data for MCA-E Renewals API calls
	 *
	 * Owner/Requester: harryhtet
	 * Good Until: unknown
	 */
	quoteRenewalsMockData = 'quoteRenewalsMockData',
	//#endregion

	//#region R
	/**
	 * Refactor IsDirectCommercial
	 * Owner/Requester: Can
	 * Good Until: 1 month after release
	 */
	refactorIsDirectCommercial = 'refactorIsDirectCommercial',
	/**
	 * Use refetch after AddBillingGroup mutation instead of update cache
	 *
	 * Owner/Requester: jejungk
	 * Good Until: done testing (Oct 2024)
	 */
	refetchAfterBg = 'refetchAfterBg',
	/**
	 * Removal of Cross Cloud (21v & Gov)
	 *
	 * Owner/Requester: simranpujji
	 * Good Until: unknown
	 */
	removalOfCrossCloud = 'removalOfCrossCloud',
	/**
	 * Remove display of discounts on product details pane
	 *
	 * Owner/Requester: Hongyu
	 * Good Until: a month after release
	 */
	removeDisplayOfDiscounts = 'removeDisplayOfDiscounts',
	/**
	 * Removes the z-index from the header row
	 *
	 * Owner/Requester: Harry
	 * Good Until: Unknown
	 */
	removeHeaderRowZIndex = 'removeHeaderRowZIndex',
	/**
	 * Switches the renew to mca create proposal to the v2 version
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	createProposalPanelV2 = 'createProposalPanelV2',
	/**
	 * Adds the market suggestion feature to the create quote panel
	 *
	 * Owner/Requester: mariamarab
	 * Good Until: Unknown
	 */
	createProposalMarketSuggestion = 'createProposalMarketSuggestion',
	/**
	 * Credit needs to be available to publish a quote
	 *
	 * Owner/Requester: Rakesh
	 * Good Until: PERMANENT - TEST ONLY
	 */
	requiredCredit = 'requiredcredit',
	/**
	 * Review and Publish Page style change
	 *
	 * Owner/Requester: Fiona
	 * Good Until: Unknow
	 * For Testing Only
	 */
	reviewAndPublishStyle = 'reviewAndPublishStyle',
	/**
	 * Enable RI Configuration Panel
	 *
	 * Owner/Requester: Can
	 * Good Until: Unknown
	 */
	riConfigurationPanel = 'riConfigurationPanel',
	//#endregion

	//#region S
	/**
	 * Uses onboarded email address to create DD360 ticket, instead of user email
	 *
	 * Owner/Requestor: Jenn/Megan
	 * Good Until: PERMANENT - TEST ONLY
	 */
	safeListedBd360Requestor = 'safelistedbd360requestor',
	/**
	 * Credit check uses a safelisted organization
	 *
	 * Owner/Requester: Jason
	 * Good Until: Replace with test headers
	 */
	safelistedCreditCheck = 'safelistedcreditcheck',
	/**
	 * Use longer axios timeout for searchByMeterId
	 *
	 * Owner/Requester: Harry
	 * Good Until: TBD
	 */
	searchByMeterIdLongerTimeout = 'searchByMeterIdLongerTimeout',
	/**
	 * Make sequential calls of Download Shared Meters GQL calls
	 *
	 * Owner/Requester: Harry
	 * Good Until: TBD
	 */
	sequenceDownloadSharedMetersGql = 'sequenceDownloadSharedMetersGql',
	/**
	 * Migrate Service Desk API to v3
	 *
	 * Owner/Requester: Mariam Arab
	 * Good Until: A month after release - 02/15/2024
	 */
	/**
	 * Simulate credit check to get null
	 *
	 * Owner/Requester:Vijay
	 * Good Until: Unknown
	 */
	simulateCreditCheckNull = 'simulateCreditCheckNull',
	/**
	 * Simulate credit check to get manual cancelled
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	simulateCreditCheckManualCancelled = 'simulateCreditCheckManualCancelled',
	/**
	 * Simulate credit check to get manual rejected
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	simulateCreditCheckManualRejected = 'simulateCreditCheckManualRejected',
	/**
	 * Simulate credit check to get manual review
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	simulateCreditCheckManualReview = 'simulateCreditCheckManualReview',
	/**
	 * Simulate credit check to get pending no credit
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	simulateCreditCheckPendingNoCredit = 'simulateCreditCheckPendingNoCredit',
	/**
	 * Simulate credit check to get pending review
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	simulateCreditCheckPendingReview = 'simulateCreditCheckPendingReview',
	/**
	 * Simulate tradeScreen status to get need screening
	 *
	 * Owner/Requester:Vijay
	 * Good Until: Unknown
	 */
	simulateTradeStatusNeedScreening = 'simulateTradeStatusNeedScreening',
	/**
	 * Show Date Is In The Past Error For Shared Discount
	 * Owner/Requester: Can
	 * Good Until: Unknown
	 */
	showDateIsInThePastErrorForSharedDiscount = 'showDateIsInThePastErrorForSharedDiscount',
	/**
	 * Enables Software Subscriptions feature
	 *
	 * Owner/Requester: jejungk
	 * Good Until: Release tenatively July 2024 - task.ms/49474376
	 */
	softwareSubs = 'softwareSubs',
	/**
	 * Enables Future Discounting for Software Subscriptions
	 *
	 * Owner/Requester: jejungk
	 * Good Until: Release sometime after July 2024 - task.ms/51210056 and task.ms/51210082
	 */
	swSubFutureDiscounts = 'swSubFutureDiscounts',
	/**
	 * Discoverability and configuration of service speficic credits (SSC) and contingent service specific credits (CSSC)
	 *
	 * Owner/Requester: Julie/Manisha/Jenn
	 * Good Until: Unknown
	 */
	ssc = 'ssc',
	/**
	 * This enables the ability to create Legacy proposal alone with service speficic credits (SSC) context
	 *
	 * Owner/Requester: Julie/Vijay
	 * Good Until: Unknown
	 */
	sscLegacy = 'sscLegacy',
	/**
	 * Allows future start date for subscription management
	 *
	 * Owner/Requester: Nanor/Tiffany
	 * Good Until: a month after release
	 */
	subscriptionManagementFutureStartDate = 'subscriptionManagementFutureStartDate',
	/**
	 * Enable wizard experience for Subscription Update + Configuration
	 *
	 * Owner/Requester: Harry/Tiffany
	 * Good Until: a month after release
	 */
	subscriptionWizard = 'subscriptionWizard',
	//#endregion

	//#region T
	/**
	 * Removes tenantAdmin call
	 *
	 * Owner/Requester: cassandraho
	 * Good Until: Unknown
	 */
	tenantAdminDeprecation = 'tenantAdminDeprecation',
	/**
	 * Allows searching of negotiated terms in the Finder
	 *
	 * Owner/Requester: Julie
	 * Good Until: PERMANENT - TEST ONLY
	 */
	termsFinderSearch = 'termsFinderSearch',
	/**
	 * Test header retention to 3 years instead of 6 months.
	 *
	 * Owner/Requester: Vijay
	 * Good Until: PERMANENT - TEST ONLY
	 */
	testHeader3YearsRetention = 'testHeader3YearsRetention',
	/**
	 * Console log flights
	 *
	 * Owner/Requester: Ozgun
	 * Good Until: PERMANENT - TEST ONLY
	 */
	TestFlight1 = 'testflight1',
	/**
	 * Throttle Download Shared Meters' Catalog Products Lookup call
	 *
	 * Owner/Requester: Harry
	 * Good Until: TBD
	 */
	throttleSearchByMeterId = 'throttleSearchByMeterId',
	/**
	 * Test Flight for testing changes to enable the ability to create a large quote with DFD and non-DFD products
	 */
	testLargeQuoteDFDNONDFD = 'testLargeQuoteDFDNONDFD',
	/**
	 * Re-architure covert to custom term button
	 *
	 * Owner/Requester: vchayammagar
	 * Good Until: Until Terms fragment gets build
	 */
	termsHarmonyConvertButton = 'termsHarmonyConvertButton',
	//#endregion

	/**
	 * Fetch cached product data from storage account blob
	 *
	 * Owner/Requestor: Hongyu
	 * Good Until: Validation complete
	 */
	useBlobForCatalogCaching = 'useBlobForCatalogCaching',
	/**
	 * Use new 1PA Scope for EA Admin
	 *
	 * Owner/Requestor: Ritesh
	 * Good Until: Validation complete
	 */
	useNewEAAdminScope = 'useNewEAAdminScope',
	/**
	 * Uses fluent panel for Azure Plan
	 *
	 * Owner/Requestor: Harry
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForAzurePlan = 'useFluentPanelForAzurePlan',
	/**
	 * Uses fluent panel for ACD
	 * Owner/Requestor: Harry
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForACD = 'useFluentPanelForACD',
	/**
	 * Uses fluent panel for Discount configuration
	 * Owner/Requestor: Harry
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForDiscountConfig = 'useFluentPanelForDiscountConfig',
	/**
	 * Uses fluent panel for Meters
	 * Owner/Requestor: Hongyu
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForMeters = 'useFluentPanelForMeters',
	/**
	 * Uses fluent panel for Modern Office
	 * Owner/Requestor: Harry
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForModernOffice = 'useFluentPanelForModernOffice',
	/**
	 * Uses fluent panel for Monetary
	 * Owner/Requestor: Harry
	 * Good Until: After repo migration to Harmony SolidJS
	 */
	useFluentPanelForMonetary = 'useFluentPanelForMonetary',
	/**
	 * Use new scope for Balance Manager service
	 * Owner/Requestor: Vijay
	 * Good Until: After 30 days of prodution release
	 */
	useNewScopeForBalanceManager = 'useNewScopeForBalanceManager',
	/**
	 * Uses PMW 1P certificate to make Mookcake and Fairfax Graph API Calls.
	 *
	 * Owner/Requestor: Ritesh
	 * Good Until: TBD
	 */
	usePMWCertForGraphAPI = 'usePMWCertForGraphAPI',
	/**
	 * Uses node workers to parse large product data in parallel
	 *
	 * Owner/Requestor: Mariam
	 * Good Until: TBD
	 */
	/**
	 * Adds programCode to quote for Direct-Commercial quote
	 * Tells GQL to use template info returned by Quotes service
	 *
	 * Owner/Requestor: Jenn/Jason Schneider
	 * Good Until: TBD
	 */
	useQuoteProgramCode = 'useQuoteProgramCode',
	/**
	 * Uses the duration, enddate, and startDate values straight off of the quote line item
	 *
	 * Owner/Requestor: Manisha
	 * Good Until: TBD
	 */
	useRawDateAndDurationValues = 'useRawDateAndDurationValues',
	/**
	 * Uses FIC as credential in backend to acquire token from 1PA instead of certificate.
	 *
	 * Owner/Requestor: Navya Jeevan
	 * Good Until: TBD
	 */
	use1PAFIC = 'use1PAFIC',
	//#endregion

	//#region V
	/**
	 * Vanguard quote context
	 *
	 * Owner/Requester: Manisha
	 * Release date: 5/1
	 */
	vanguard = 'vanguard',
	/**
	 * Add test header to scan virus via MSDefender
	 *
	 * Owner/Requester: Neha/Harry
	 * Release date: 05/07
	 */
	virusScanTestHeader = 'virusScanTestHeader',
	//#endregion

	//#region W
	//#endregion

	//#region X
	//#endregion

	//#region Y
	//#endregion

	//#region Z
	//#endregion
}

// Any flights enabled here will override control tower
export const productionFlights: Record<Flight, boolean> = {
	//#region Temporary Flights
	// --- Temporary Flights: Remove after a month in Production ---------
	// Please update 'Good Until' date above when turning a flight ON to track cleanup
	[Flight.accountExtensionsOrgSearchMoreResults]: false,
	[Flight.addOnMaxPurchaseInfo]: false,
	[Flight.allowEndDateWithoutStartDateForAzureCommitmentDiscount]: false,
	// When this(alwaysLoadV12QuoteInSagas) is enabled, it causes issues(https://portal.microsofticm.com/imp/v5/incidents/details/563093618/summary) on some flows, do not turn it on.
	[Flight.alwaysLoadV12QuoteInSagas]: false,
	[Flight.assetEvents]: false,
	[Flight.assetFilters]: true,
	[Flight.assetPanelUxChanges]: false,
	[Flight.azureSupportConfigFlyInPanel]: false,
	[Flight.allowUnspecifiedOptionInLocationFilters]: true,
	[Flight.billingProfileForDiscounts]: false,
	[Flight.breadcrumbs]: false,
	[Flight.canCancelAsset]: false,
	[Flight.cloneProposal]: false,
	[Flight.convertDatesToISOStringOnBulkDiscount]: true,
	[Flight.convergence]: false,
	[Flight.cotermWithLineItemFromProposal]: false,
	[Flight.cotermWithLineItemFromProposalMockData]: false,
	[Flight.cwaMultiMaccContriEntity]: false,
	[Flight.cwaPlannedAccounts]: false,
	[Flight.cwaShortfallPostPaidAllowZero]: false,
	[Flight.cwaShortfallPanelChanges]: false,
	[Flight.cwaTenantAccountCheck]: false,
	[Flight.dealTotalityBenefitsOnQuote]: true,
	[Flight.deletedTenantCheck]: false,
	[Flight.deduplicateProductIdsOnMultiSelect]: false,
	[Flight.delaySearchByMeterId]: false,
	[Flight.disableSagasAndEpics]: false,
	[Flight.fixAccidentalShowingOfAllDiscountsAddedInSharedDiscountPanel]: true,
	[Flight.graphAuthorizationUsingQCApp]: false,
	[Flight.graphToCreateSPNUsingQCApp]: false,
	[Flight.demoMode]: false,
	[Flight.directComNonDFD]: false,
	[Flight.directFGOE]: false,
	[Flight.directGov]: false,
	[Flight.directGovNonDFD]: false,
	[Flight.doNotUpdateLineItemRecipientAndAssetTo]: false,
	[Flight.downloadPDFConvertChanges]: false,
	[Flight.e2Fragments]: false,
	[Flight.e2Router]: true,
	[Flight.enableApolloRequestInspectorClientFilters]: false,
	[Flight.enableDetailsPaneLabelGrid]: false,
	[Flight.enableMetersLineItem]: false,
	[Flight.enableQuickLookupForSharedMeters]: false,
	[Flight.enableServiceDeskAttachment]: false,
	[Flight.endDateAdjustment]: false,
	[Flight.esGraduatedPricingOnlyForAzure]: true,
	[Flight.estsrRegionAutodetect]: true,
	[Flight.enableStampConfigV2]: false,
	[Flight.enableQCSMigrationwith1PADD360]: false,
	[Flight.enableDD360AttachmentGQL]: false,
	[Flight.filterNoSkuNoAvailabilityProducts]: false,
	[Flight.graphDepPublicTenantValidation]: false,
	[Flight.gsmProject]: false,
	[Flight.guidedTour]: false,
	[Flight.hideSharedMeters]: false,
	[Flight.hideAffiliateFedGov]: false,
	[Flight.hideUnconfiguredProductConfigDetails]: true,
	[Flight.indirectComChinaNonDFD]: false,
	[Flight.indirectComCSPNonDFD]: false,
	[Flight.indirectGovNonDFD]: false,
	[Flight.indirectPartnerCustomerFootprintRetry]: true,
	[Flight.largeQuote1]: false,
	[Flight.lazyLoadProductFilters]: false,
	[Flight.legacyNonDFD]: false,
	[Flight.limitBillingAccountByMarket]: false,
	[Flight.loadMinimumRequiredPagesForSharedDiscount]: false,
	[Flight.maccModifyCancelPanelCWA]: false,
	[Flight.maximumSkuLimitReachedMessage]: false,
	[Flight.multiSelectTeachingBubble]: true,
	[Flight.mutationAuth]: false,
	[Flight.newAuthFlow]: true,
	[Flight.nonStandardFrameworkNonDFD]: false,
	[Flight.offlineAgreementNewFlow]: false,
	[Flight.pricelist]: true,
	[Flight.pricelistCartAcross]: true,
	[Flight.pricelistExpandedView]: true,
	[Flight.pricelistQuoteFilters]: true,
	[Flight.productInclusionCheckerPanel]: false,
	[Flight.productSearchMoreResults]: false,
	[Flight.productSearchRemoveDuplicateResult]: true,
	[Flight.promotionProductOwnerShipFix]: false,
	[Flight.proposalSteps]: false,
	[Flight.qcInMSX]: false,
	[Flight.mcapiPolicy]: false,
	[Flight.qcsMigrationServiceDeskAttachment1PA]: false,
	[Flight.qsElmEnforce]: false,
	[Flight.quoteHeaderMessages]: true,
	[Flight.quoteRenewals]: false,
	[Flight.quoteRenewalsBillingRP]: false,
	[Flight.quoteRenewalsMockData]: false,
	[Flight.refactorIsDirectCommercial]: false,
	[Flight.refetchAfterBg]: false,
	[Flight.removalOfCrossCloud]: false,
	[Flight.removeDisplayOfDiscounts]: true,
	[Flight.removeHeaderRowZIndex]: false,
	[Flight.createProposalPanelV2]: false,
	[Flight.createProposalMarketSuggestion]: true,
	[Flight.reviewAndPublishStyle]: true,
	[Flight.riConfigurationPanel]: false,
	[Flight.searchByMeterIdLongerTimeout]: false,
	[Flight.sequenceDownloadSharedMetersGql]: false,
	[Flight.showDateIsInThePastErrorForSharedDiscount]: false,
	[Flight.softwareSubs]: false,
	[Flight.swSubFutureDiscounts]: false,
	[Flight.ssc]: false,
	[Flight.sscLegacy]: false,
	[Flight.subscriptionManagementFutureStartDate]: false,
	[Flight.subscriptionWizard]: false,
	[Flight.tenantAdminDeprecation]: false,
	[Flight.termsHarmonyConvertButton]: false,
	[Flight.throttleSearchByMeterId]: false,
	[Flight.useBlobForCatalogCaching]: true,
	[Flight.useNewEAAdminScope]: false,
	[Flight.useFluentPanelForAzurePlan]: true,
	[Flight.useFluentPanelForModernOffice]: true,
	[Flight.useFluentPanelForMonetary]: true,
	[Flight.useFluentPanelForACD]: true,
	[Flight.useFluentPanelForDiscountConfig]: true,
	[Flight.useFluentPanelForMeters]: false,
	[Flight.useNewScopeForBalanceManager]: true,
	[Flight.usePMWCertForGraphAPI]: false,
	[Flight.useQuoteProgramCode]: false,
	[Flight.useRawDateAndDurationValues]: false,
	[Flight.use1PAFIC]: false,
	[Flight.vanguard]: false,
	[Flight.virusScanTestHeader]: false,
	//#endregion
	//#region Permanent Flights
	// --- Permanent Flights: DO NOT REMOVE ---------------------
	// Generally, these flights are for test, or available to assist with specific Prod issues on an as-needed basis (ie: bulkApproveTransact)
	// Put Flights for Feature work in Temporary Flights region above
	[Flight.acoStartEndDate]: false,
	[Flight.airCapi]: false, // Test-only
	[Flight.approvalOverride]: false, // Test-only
	[Flight.bulkApproveTransact]: false,
	[Flight.currencyOverride]: false,
	[Flight.cwaByModernIds]: false, // Test-only; NEVER turn on by default
	[Flight.disableBulkEditForPasses]: true,
	[Flight.disableProductInclusionFilter]: false, // Test-only
	[Flight.disableTestTermRecommendations]: true, // Test-only; ALWAYS on by default so we do not expose test products in Prod
	[Flight.displayEtagsForDebugging]: false, // Test-only
	[Flight.displayTestMCAs]: false, // Test-only; NEVER turn on by default
	[Flight.enableApolloRequestInspector]: false,
	[Flight.enableHRDDDocHold]: false, // Do not enable, only for testing HRDD Doc Hold
	[Flight.enableReferralCloseButtonForTesting]: false, // Test-only
	[Flight.excludeSavingsPlan]: false,
	[Flight.filterForBillingAccountOwners]: false,
	[Flight.forceFutureDiscountOptions]: false, // Test-only; NEVER turn on by default
	[Flight.ignoreNonExistingQuotesOnSearchIndex]: false,
	[Flight.ignoreTestEmailValidation]: false, // Test-only; NEVER turn on by default
	[Flight.lineItemLimitIncrease]: false, // Test-only; To be used with test headers only
	[Flight.measureSharedMetersPerformance]: false, // Test-only; To use to measure some possible performance improvements NEVER turn on by default
	[Flight.noTestHeaderTesting]: false, // should always be false since is used from control tower for specific aliases. See configurations.ts for more info if you need.
	[Flight.obfuscateTranslations]: false, // Test-only
	[Flight.physicalSignForUkccf]: false, // Test-only
	[Flight.pidSearch]: true,
	[Flight.requiredCredit]: true, // Test-only; ALWAYS on by default so we don't skip credit requirements in Prod
	[Flight.safeListedBd360Requestor]: false, // Test-only; NEVER turn on by default
	[Flight.safelistedCreditCheck]: false, // Test-only; NEVER turn on by default
	[Flight.simulateCreditCheckNull]: false, //Test -only
	[Flight.simulateCreditCheckManualCancelled]: false, //Test -only
	[Flight.simulateCreditCheckManualRejected]: false, //Test -only
	[Flight.simulateCreditCheckManualReview]: false, //Test -only
	[Flight.simulateCreditCheckPendingNoCredit]: false, //Test -only
	[Flight.simulateCreditCheckPendingReview]: false, //Test -only
	[Flight.simulateTradeStatusNeedScreening]: false, //Test -only
	[Flight.termsFinderSearch]: false, // Test-only
	[Flight.TestFlight1]: false, // Test-only
	[Flight.testHeader3YearsRetention]: false, // Test-only
	[Flight.testLargeQuoteDFDNONDFD]: false, // Test-only
	//#endregion
};

export const latestFlights: Record<Flight, boolean> = {
	...productionFlights,
	[Flight.addOnMaxPurchaseInfo]: true,
	[Flight.allowEndDateWithoutStartDateForAzureCommitmentDiscount]: true,
	[Flight.assetEvents]: true,
	[Flight.azureSupportConfigFlyInPanel]: true,
	[Flight.breadcrumbs]: true,
	[Flight.deletedTenantCheck]: true,
	[Flight.disableSagasAndEpics]: true,
	[Flight.disableTestTermRecommendations]: false,
	[Flight.downloadPDFConvertChanges]: true,
	[Flight.lineItemLimitIncrease]: true,
	[Flight.mutationAuth]: true,
	[Flight.pidSearch]: true,
	[Flight.productSearchMoreResults]: true,
	[Flight.promotionProductOwnerShipFix]: true,
	[Flight.proposalSteps]: true,
	[Flight.riConfigurationPanel]: true,
	[Flight.refactorIsDirectCommercial]: true,
	[Flight.safelistedCreditCheck]: true,
	[Flight.softwareSubs]: true,
	[Flight.termsFinderSearch]: true,
	[Flight.TestFlight1]: true,
	[Flight.useFluentPanelForAzurePlan]: true,
	[Flight.useFluentPanelForMeters]: true,
	[Flight.useQuoteProgramCode]: true,
	[Flight.quoteHeaderMessages]: true,
	[Flight.qsElmEnforce]: true,
	[Flight.useNewEAAdminScope]: true,
	[Flight.useRawDateAndDurationValues]: true,
	[Flight.virusScanTestHeader]: true,
	[Flight.use1PAFIC]: true,
};

export const stableFlights: Record<Flight, boolean> = {
	...productionFlights,
	[Flight.addOnMaxPurchaseInfo]: true,
	[Flight.allowEndDateWithoutStartDateForAzureCommitmentDiscount]: true,
	[Flight.assetEvents]: true,
	[Flight.azureSupportConfigFlyInPanel]: true,
	[Flight.disableSagasAndEpics]: true,
	[Flight.disableTestTermRecommendations]: false,
	[Flight.downloadPDFConvertChanges]: true,
	[Flight.lineItemLimitIncrease]: true,
	[Flight.mutationAuth]: true,
	[Flight.pidSearch]: true,
	[Flight.productSearchMoreResults]: true,
	[Flight.promotionProductOwnerShipFix]: true,
	[Flight.refactorIsDirectCommercial]: true,
	[Flight.removeHeaderRowZIndex]: true,
	[Flight.riConfigurationPanel]: true,
	[Flight.safelistedCreditCheck]: true,
	[Flight.softwareSubs]: true,
	[Flight.termsFinderSearch]: false,
	[Flight.TestFlight1]: true,
	[Flight.useFluentPanelForAzurePlan]: true,
	[Flight.useFluentPanelForMeters]: true,
	[Flight.useNewEAAdminScope]: true,
	[Flight.useQuoteProgramCode]: true,
	[Flight.quoteHeaderMessages]: true,
	[Flight.useRawDateAndDurationValues]: true,
	[Flight.virusScanTestHeader]: true,
	[Flight.use1PAFIC]: true,
};

export const testFlights: Record<Flight, boolean> = {
	...stableFlights,
	[Flight.breadcrumbs]: true,
	[Flight.proposalSteps]: true,
	[Flight.qsElmEnforce]: true,
};

export const insiderFlights: Record<Flight, boolean> = {
	...productionFlights,
	[Flight.TestFlight1]: true,
};

export const demoFlights: Record<Flight, boolean> = {
	...productionFlights,
	[Flight.approvalOverride]: true,
	[Flight.demoMode]: true,
	[Flight.requiredCredit]: false,
	[Flight.safelistedCreditCheck]: true,
	[Flight.TestFlight1]: true,
};

export const qcInMSXLatestFlights: Record<Flight, boolean> = {
	...latestFlights,
	[Flight.qcInMSX]: true,
	[Flight.guidedTour]: true,
};

export const qcInMSXStableFlights: Record<Flight, boolean> = {
	...stableFlights,
	[Flight.qcInMSX]: true,
	[Flight.guidedTour]: true,
};

export const qcInMSXProdFlights: Record<Flight, boolean> = {
	...productionFlights,
	[Flight.qcInMSX]: true,
	[Flight.guidedTour]: true,
};

export const usxDemoFlights: Record<Flight, boolean> = {
	...qcInMSXLatestFlights,
};

export const fedSalesDev1Flights: Record<Flight, boolean> = {
	...qcInMSXLatestFlights,
};

export const flightList = Object.keys(productionFlights) as Flight[];

export interface FlightInformation {
	display: string;
	description: string;
	disableInEnvs?: AppEnvironment[];
	/**
	 * Do not use this to enable flights, only use it in very specific circumstances where you need to force enable some flights in dev environments
	 */
	forceEnableInEnvs?: AppEnvironment[];
	expirationDate?: Date;
	needsPageRefreshToActivate?: boolean;
}

export const flights: Record<Flight, FlightInformation> = {
	//#region A
	[Flight.accountExtensionsOrgSearchMoreResults]: {
		display: 'CWA - Billing Account Search Expanded Record Results',
		description: 'Increases Organization Search Results from 10 to 50 when flight is on',
	},
	[Flight.acoStartEndDate]: {
		display: 'ACO for Direct Start date/End date',
		description: 'New start date/end date picker requirements for Direct ACO',
		needsPageRefreshToActivate: true,
	},
	[Flight.addOnMaxPurchaseInfo]: {
		display: 'Add-on max seats constraint details',
		description: 'Provides more details regarding seat constraints to the user',
	},
	[Flight.airCapi]: {
		description: 'Allows Air Capi cloud testing',
		display: 'AirCapi',
		disableInEnvs: [AppEnvironment.Prod],
	},
	[Flight.allowEndDateWithoutStartDateForAzureCommitmentDiscount]: {
		description: 'Allow end date without start date for Azure Commitment Discount',
		display: 'Allow end date without start date for Azure Commitment Discount',
		expirationDate: new Date('November 1 2024'),
	},
	[Flight.allowUnspecifiedOptionInLocationFilters]: {
		display: 'Allow Unspecified option',
		description: 'Users can pick the Unspecified option in config cards for location',
		expirationDate: new Date('July 12 2024'),
	},
	[Flight.approvalOverride]: {
		display: 'Approval Override',
		description: 'Auto approve/reject a quote when submitted',
		disableInEnvs: [AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.assetEvents]: {
		display: 'Asset Events',
		description: 'Properly filters events for latestPurchaseRecordId in GQL',
	},
	[Flight.assetFilters]: {
		display: 'Asset Filters',
		description: 'Pass filters to Commerce Cache',
	},
	[Flight.assetPanelUxChanges]: {
		display: 'Asset Management Ux change',
		description: 'New UX experience for Asset Panel-Subscription Management Panel',
	},
	[Flight.azureSupportConfigFlyInPanel]: {
		display: 'Azure support config flyinpanel',
		description: 'Show Azure support config flyinpanel',
	},
	[Flight.enableStampConfigV2]: {
		display: 'enableStampConfigV2',
		description: 'Calls StampCofigV2 api to get all the list for aircapienvironment',
	},
	[Flight.enableQCSMigrationwith1PADD360]: {
		display: 'Enable QCS migration with 1PA - DD360',
		description: 'DD360 QCS migration enable endpoint to 1P',
	},
	[Flight.enableDD360AttachmentGQL]: {
		display: 'Enable DD360 Attachment GQL',
		description: 'Enables DD360 upload attachemnt via graphQL',
	},
	//#endregion
	//#region B
	[Flight.billingProfileForDiscounts]: {
		display: 'Billing Profile to scope all discount',
		description: 'Enables customer Billing Profiles to scope all discounts',
		needsPageRefreshToActivate: true,
	},
	[Flight.breadcrumbs]: {
		display: 'Breadcrumbs',
		description: 'Enables breadcrumbs in the Navigation Bar.',
		expirationDate: new Date('October 1 2020'),
	},
	[Flight.bulkApproveTransact]: {
		display: 'Enables bulk approve transact',
		description: 'Enables bulk approve transact',
	},
	//#endregion
	//#region C
	[Flight.canCancelAsset]: {
		display: 'Can Cancel Asset',
		description: 'Allows to cancel Asset',
		needsPageRefreshToActivate: true,
	},
	[Flight.convertDatesToISOStringOnBulkDiscount]: {
		display: 'Convert dates to ISO String on Bulk Discount',
		description: 'Convert dates to ISO String on Bulk Discount',
		expirationDate: new Date('December 5 2024'),
		needsPageRefreshToActivate: true,
	},
	[Flight.convergence]: {
		display: 'Convergence',
		description: 'Convergence Workspace Experience',
	},
	[Flight.cotermWithLineItemFromProposal]: {
		display: 'Coterm with Line Item from Proposal',
		description: 'Allows to coterm with line item from proposal',
	},
	[Flight.cotermWithLineItemFromProposalMockData]: {
		display: 'Coterm with Line Item from Proposal using Mock Data',
		description: 'Allows to coterm with line item from proposal using Mock Data',
	},
	[Flight.cloneProposal]: {
		display: 'Clone proposal',
		description: 'Enables a button in quote summary that allows user to clone a proposal',
	},
	[Flight.currencyOverride]: {
		display: 'Currency Override',
		description: 'Use the currency override for this market',
		disableInEnvs: [AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.cwaByModernIds]: {
		display: 'CWA - CWA by modern ids',
		description:
			'Debug only. Enables using CWA by org id or tenant id instead of tpid. Only for testing purposes in CWA',
	},
	[Flight.cwaMultiMaccContriEntity]: {
		display: 'CWA - Display Contributors ME MACC',
		description: 'Displays COntributors list for Primary BA if exists',
	},
	[Flight.cwaPlannedAccounts]: {
		display: 'CWA - Show Planned accounts in Billing Accounts section',
		description: 'Shows dropdown and planned accounts in Billing Accounts',
	},
	[Flight.cwaShortfallPostPaidAllowZero]: {
		display: 'CWA - MACC shortfall allow zero for post paid',
		description: 'Enables entering "0" for the amount for post paid MACC shortfall invoices.',
	},
	[Flight.cwaShortfallPanelChanges]: {
		display: 'CWA - Shortfall Panel Changes',
		description: 'Shows and updated shortfall panel without billing account owner',
	},
	[Flight.cwaTenantAccountCheck]: {
		display: 'CWA - Enable Tenant Account Check',
		description: 'Enable checking if the tenant is associated with a valid MCAPI account',
	},
	//#endregion
	//#region D
	[Flight.dealTotalityBenefitsOnQuote]: {
		display: 'Deal Totality benefits on Quote',
		description: 'Allows us see Approved benefits and benefits on quote separately',
	},
	[Flight.deduplicateProductIdsOnMultiSelect]: {
		display: 'Deduplicate Product Ids on Multi Select',
		description: 'Deduplicate Product Ids on Multi Select',
		needsPageRefreshToActivate: true,
	},
	[Flight.delaySearchByMeterId]: {
		display: 'Delay Search By Meter Id',
		description: 'Delays the download of shared meters',
	},
	[Flight.deletedTenantCheck]: {
		display: 'Deleted tenant check',
		description:
			"Enables publish-blocking messages to check if the partner or customer's tenant still exists.",
		needsPageRefreshToActivate: true,
	},
	[Flight.demoMode]: {
		display: 'Demo mode theme',
		description: 'Enables Demo mode theme',
		expirationDate: new Date('October 1 2020'),
		disableInEnvs: [AppEnvironment.Prod],
	},
	[Flight.directComNonDFD]: {
		display: 'Direct-Commercial Non-DFD',
		description: 'Allows direct-commercial quotes to be pass through non-DFD flow',
	},
	[Flight.directFGOE]: {
		display: 'Direct FGOE',
		description: 'Allows selection of fgoe on a direct-goverment quote',
		expirationDate: new Date('November 1 2020'),
	},
	[Flight.directGov]: {
		display: 'Direct-Gov',
		description: 'Allows direct-gov quotes to be created',
		expirationDate: new Date('November 1 2020'),
	},
	[Flight.directGovNonDFD]: {
		display: 'Direct-Gov Non-DFD',
		description: 'Allows direct-gov quotes to be pass through non-DFD flow',
	},
	[Flight.disableBulkEditForPasses]: {
		display: 'Disable Bulk Edit for Passes Product',
		description: 'Disable Bulk Edit for Passes Product',
	},
	[Flight.disableSagasAndEpics]: {
		display: 'Disable Sagas and Epics',
		description: 'Disable Sagas and Epics',
	},
	[Flight.displayEtagsForDebugging]: {
		display: 'Display Etags',
		description: 'Display Etags for debugging purposes',
	},
	[Flight.displayTestMCAs]: {
		display: 'CWA - Display Production Quote Data',
		description:
			'TESTING ONLY. Causes all quote data to be loaded from production. Never turn on by default; only for testing purposes in CWA.',
		disableInEnvs: [AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.disableProductInclusionFilter]: {
		display: 'Search all products',
		description: 'disable filters applied after product search',
		needsPageRefreshToActivate: true,
	},
	[Flight.disableTestTermRecommendations]: {
		display: 'Disable test term recommendations',
		description: 'Disable test term recommendations.',
		needsPageRefreshToActivate: true,
	},
	[Flight.doNotUpdateLineItemRecipientAndAssetTo]: {
		display: 'Do not update line item recipient and asset to',
		description:
			'Do not update line item recipient and asset to when we have a customized price(NON-DFD) on the line item',
		needsPageRefreshToActivate: true,
	},
	[Flight.downloadPDFConvertChanges]: {
		display: 'PDF UX passes product lineitem Changes',
		description: 'Enables the PDF UX enhancements for passes product lineitem',
	},
	//#endregion
	//#region E
	[Flight.e2Fragments]: {
		display: 'E2 Fragments',
		description: 'Enables the use of E2 Fragments',
		needsPageRefreshToActivate: true,
	},
	[Flight.e2Router]: {
		display: 'E2 Router',
		description: 'Enables the use of E2 Router',
		needsPageRefreshToActivate: true,
	},
	[Flight.enableApolloRequestInspector]: {
		display: 'Enable Apollo Request Inspector',
		// Turning this flight on won't by itself enable the apollo request inspector, for it to be enabled it should also be enabled in the backend in appsettings.json of the environment AND you should have the necessary role.
		disableInEnvs: [AppEnvironment.Stable, AppEnvironment.Prod],
		description:
			'Enables Apollo Request Inspector, When turned off, you would not get any debug headers in the response.',
		needsPageRefreshToActivate: true,
	},
	[Flight.enableApolloRequestInspectorClientFilters]: {
		display: 'Enable Apollo Request Inspector Client Filters',
		description: 'Enables Apollo Request Inspector Client Filters',
		disableInEnvs: [AppEnvironment.Stable, AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.enableDetailsPaneLabelGrid]: {
		description: 'uses css grids for the labels on details pane',
		display: 'Details pane grid',
	},
	[Flight.enableMetersLineItem]: {
		display: 'Enable metersLineItem type',
		description: 'Enable metersLineItem type',
	},
	[Flight.enableQuickLookupForSharedMeters]: {
		description:
			'Enable quick lookup for shared meters download (does not affect search shared meters option in UX)',
		display: 'Quick lookup for shared meters download',
		expirationDate: new Date('September 1 2024'),
		needsPageRefreshToActivate: true,
	},
	[Flight.enableReferralCloseButtonForTesting]: {
		description: 'Enable referral close button for testing',
		display: 'Enable Close Button',
		disableInEnvs: [AppEnvironment.Prod],
	},
	[Flight.enableServiceDeskAttachment]: {
		display: 'Enable Service Desk Attachment',
		description: 'Enables Service Desk Attachment',
	},
	[Flight.endDateAdjustment]: {
		description: 'Enables the end date adjustment for the quote',
		display: 'End Date Adjustment',
		expirationDate: new Date('July 1 2024'),
	},
	[Flight.esGraduatedPricingOnlyForAzure]: {
		display: 'Ent Support Graduated Pricing Only For Azure',
		description: 'Update text to reflect Ent Support Graduated Pricing Only For Azure',
	},
	[Flight.estsrRegionAutodetect]: {
		display: 'ESTS-R Region Autodetect',
		description: 'Enables ESTS-R in the backend',
	},
	[Flight.excludeSavingsPlan]: {
		display: 'Exclude SavingsPlan',
		description: 'Exclude SavingsPlan from inclusion list',
		needsPageRefreshToActivate: true,
	},
	//#endregion
	//#region F
	[Flight.filterForBillingAccountOwners]: {
		display: 'Filter For Billing Account Owners',
		description:
			'Only display eligible Billing Account Owners when selecting billing account owner',
	},
	[Flight.filterNoSkuNoAvailabilityProducts]: {
		display: 'Filter No Sku and Availability Products',
		description: 'Only display products that have sku and availability',
	},
	[Flight.forceFutureDiscountOptions]: {
		display: 'Force Future Discount Options',
		description: 'Forces SubscriptionProductConfigurationPanel to show future discount options',
		disableInEnvs: [AppEnvironment.Prod],
	},
	[Flight.fixAccidentalShowingOfAllDiscountsAddedInSharedDiscountPanel]: {
		display: 'Fix Accidental Showing Of All Discounts Added In Shared Discount Panel',
		description: 'Fix Accidental Showing Of All Discounts Added In Shared Discount Panel',
	},
	//#endregion
	//#region G
	[Flight.graphAuthorizationUsingQCApp]: {
		display: 'Graph authorization using QC',
		description: 'Graph authorization using QC',
	},
	[Flight.graphToCreateSPNUsingQCApp]: {
		display: 'Graph Service Principal using QC',
		description: 'Graph Service Principal using QC',
	},
	[Flight.graphDepPublicTenantValidation]: {
		display: 'Graph Deprecation - Public Tenant Validation',
		description:
			'Remove UI and calls related to MSGraph, replaces the existing Removal Of Cross Cloud flight',
	},
	[Flight.gsmProject]: {
		display: 'GSM Project',
		description: 'Enables to retrieve MCAPI footprint for legacy enrollment using GSM API.',
	},
	[Flight.guidedTour]: {
		display: 'Guided Tour',
		description: 'Enables guided tour for quote center',
	},
	//#endregion
	//#region H
	[Flight.hideAffiliateFedGov]: {
		display: 'CWA - Hides Affiliate for FedGov',
		description: 'Hides the Affiliate FedGov option',
	},
	[Flight.hideSharedMeters]: {
		display: 'Hide shared meters download',
		description: 'Hides the option to download shared meters',
	},
	[Flight.hideUnconfiguredProductConfigDetails]: {
		display: 'Hide unconfigured product config details',
		description:
			'Hides the Product configuration section in details pane if product is not configured',
	},
	//#endregion
	//#region I
	[Flight.ignoreNonExistingQuotesOnSearchIndex]: {
		display: 'Ignore non existing quotes',
		description:
			'There are some quotes that are returned in search index but they actually do not exist, the search index is out of sync, this enables to return search results on quotes with ignoring those ones',
		needsPageRefreshToActivate: true,
	},
	[Flight.ignoreTestEmailValidation]: {
		display: 'Ignore test email validation',
		description: 'Ignore test email validation in test environment',
		needsPageRefreshToActivate: true,
	},
	[Flight.indirectComChinaNonDFD]: {
		display: 'Indirect-Commercial China Non-DFD',
		description: 'Allows indirect-commercial China quotes to be pass through non-DFD flow',
	},
	[Flight.indirectComCSPNonDFD]: {
		display: 'Indirect-Commercial CSP Non-DFD',
		description: 'Allows indirect-commercial CSP quotes to be pass through non-DFD flow',
	},
	[Flight.indirectGovNonDFD]: {
		display: 'Indirect-Gov Non-DFD',
		description: 'Allows indirect-gov quotes to be pass through non-DFD flow',
	},
	[Flight.indirectPartnerCustomerFootprintRetry]: {
		display: 'CWA - Indirect Partner Customer Footprint Retry',
		description: 'Changes the retry logic for indirect partner customer footprint',
	},
	[Flight.enableHRDDDocHold]: {
		display: 'Enable HRDD Doc Hold',
		description: 'Enable HRDD Doc Hold',
		needsPageRefreshToActivate: true,
	},
	//#endregion
	//#region J
	//#endregion
	//#region K
	//#endregion
	//#region L
	[Flight.largeQuote1]: {
		display: 'Large Quote 1',
		description: 'Uses QS v13 to support crafting Large Quotes',
	},
	[Flight.lazyLoadProductFilters]: {
		display: 'Lazy load product filters',
		description: 'Load product filters after other line item details',
	},
	[Flight.legacyNonDFD]: {
		display: 'Legacy Non-DFD',
		description: 'Allows legacy quotes to be pass through non-DFD flow',
	},
	[Flight.limitBillingAccountByMarket]: {
		display: 'Limit Billing Account By Market',
		description: 'Limits organization search/creation by the market of the quote',
	},
	[Flight.lineItemLimitIncrease]: {
		display: 'Line Item Limit Increase',
		description: 'Removes the lineitem limit restrictions',
	},
	[Flight.loadMinimumRequiredPagesForSharedDiscount]: {
		display: 'load minimum required pages for shared discount',
		description: 'Get the minimum required pages to decide on enabling shared discounts',
	},
	[Flight.alwaysLoadV12QuoteInSagas]: {
		display: 'v12 Quote in Sagas',
		description: 'Always load v12 quote in sagas',
	},
	//#endregion
	//#region M
	[Flight.maccModifyCancelPanelCWA]: {
		display: 'CWA - MACC Modify/Cancel Panel',
		description: 'Enables MACC modify/cancel panel for CWA',
	},
	[Flight.maximumSkuLimitReachedMessage]: {
		display: 'Max SKU limit reached message',
		description: 'Maximum SKU limit reached line item message reflects Validation API',
		expirationDate: new Date('September 30 2023'),
	},
	[Flight.measureSharedMetersPerformance]: {
		display: 'Measure shared meters performance',
		description: 'Measure shared meters performance',
		needsPageRefreshToActivate: true,
	},
	[Flight.multiSelectTeachingBubble]: {
		display: 'Multi Select Teaching Bubble',
		description: 'Show teaching bubble for multi select',
		expirationDate: new Date('September 30 2024'),
	},
	[Flight.mutationAuth]: {
		display: 'Mutation Authorization',
		description: 'Enforces role authorization on all GQL mutation requests',
	},
	//#endregion
	//#region N
	[Flight.newAuthFlow]: {
		display: 'New auth flow',
		description: 'Enables the new authentication flow',
	},
	[Flight.noTestHeaderTesting]: {
		display: 'Do not turn this on',
		description: 'Seriously, please do not turn this on',
		disableInEnvs: [AppEnvironment.Prod, AppEnvironment.Latest, AppEnvironment.Local],
	},
	[Flight.nonStandardFrameworkNonDFD]: {
		display: 'Non-Standard Framework Non-DFD',
		description: 'Allows non-standard framework quotes to be pass through non-DFD flow',
	},
	//#endregion
	//#region O
	[Flight.obfuscateTranslations]: {
		display: 'Obfuscate translations',
		description: 'Makes $$$ out of all translations',
	},
	[Flight.offlineAgreementNewFlow]: {
		display: 'Create offline agreement new flow',
		description:
			'Allows to upload offline agreement and add/edit participants without deleting and record',
	},
	//#endregion
	//#region P
	[Flight.physicalSignForUkccf]: {
		display: 'Physical Signature for UK Framework',
		description: 'Allows Physical Signature for UK Framework',
	},
	[Flight.pidSearch]: {
		display: 'Product search by PID',
		description: 'Enables search for a product in finder using only product id',
		needsPageRefreshToActivate: true,
	},
	[Flight.pricelist]: {
		display: 'Pricelist',
		description: 'Enables Pricelist for search and selection of products',
	},
	[Flight.pricelistCartAcross]: {
		display: 'Cart Across Pricelist',
		description: 'Enables cart across searches for Pricelist',
	},
	[Flight.pricelistExpandedView]: {
		display: 'Expand Pricelist products',
		description:
			'Enables expanded view of Pricelist products to see SKU, commitment, billing plan, and pricing details',
	},
	[Flight.pricelistQuoteFilters]: {
		display: 'Pricelist Quote Filters',
		description: 'Constrains Pricelist filters to valid QC Quote Contexts',
	},
	[Flight.productInclusionCheckerPanel]: {
		display: 'Product Inclusion Checker Panel',
		description: 'Enables the Product Inclusion Checker Panel',
	},
	[Flight.productSearchMoreResults]: {
		display: 'Get more filtered pages for product search',
		description: 'Increases number of product search results pages from 3 to 6',
	},
	[Flight.productSearchRemoveDuplicateResult]: {
		display: 'Remove duplicate result from productSearch',
		description: 'Remove duplicate result from productSearch on Gov context',
	},
	[Flight.proposalSteps]: {
		display: 'Proposal Steps',
		description: 'Enable Proposal Steps Button for all Non 21v proposals',
	},
	[Flight.promotionProductOwnerShipFix]: {
		display: 'Promotion Product Ownership Fix',
		description: 'Fixes the product ownership issue for promotions with more number of BigIds ',
	},
	//#endregion
	//#region Q
	[Flight.qcInMSX]: {
		display: 'QC in MSX',
		description: 'Allows to view QC in MSX',
	},
	[Flight.mcapiPolicy]: {
		display: 'MCAPI Policies UX',
		description: 'MCAPI description',
	},
	[Flight.qcsMigrationServiceDeskAttachment1PA]: {
		display: 'QCS migration with 1PA - ServiceDeskAttachment',
		description: 'Enable 1PA for ServiceDeskAttachment APIs from GraphQL instead of 3PA from QCS',
	},
	[Flight.qsElmEnforce]: {
		display: 'QuoteService ELM Enforce',
		description: 'Enables ELM enforcement for QS',
	},
	[Flight.quoteHeaderMessages]: {
		display: 'Quote Header Messages',
		description: 'GQL skips processing lineItems for messages when skipLineItems is true',
	},
	[Flight.quoteRenewals]: {
		display: 'Quote Renewals',
		description: 'Features for Quote Renewals',
	},
	[Flight.quoteRenewalsBillingRP]: {
		display: 'Quote Renewals - Change subscription data',
		description: 'Get Subscription Data from BillingRP instead of Commerce Cache',
	},
	[Flight.quoteRenewalsMockData]: {
		display: 'Quote Renewals Mock Data',
		description: 'Mock Data for Quote Renewals',
	},
	//#endregion
	//#region R
	[Flight.refactorIsDirectCommercial]: {
		display: 'Refactor isDirectCommercial',
		description: 'Refactor isDirectCommercial',
	},
	[Flight.refetchAfterBg]: {
		display: 'Refetch after AddBillingGroup mutation',
		description: 'Refetch after AddBillingGroup mutation',
	},
	[Flight.removalOfCrossCloud]: {
		display: 'Removal of Cross Cloud (21v & Gov)',
		description:
			'This will prevent any cross cloud graph calls, specifically targeting China 21V and FedGov scenarios',
	},
	[Flight.removeDisplayOfDiscounts]: {
		display: 'Remove display of discounts on product details pane',
		description: 'Remove display of discounts on product details pane',
	},
	[Flight.removeHeaderRowZIndex]: {
		display: 'Remove headerRow z-index',
		description: 'Remove headerRow z-index',
	},
	[Flight.createProposalPanelV2]: {
		display: 'CWA - Create Proposal V2',
		description: 'Enables the new Create Proposal V2 flow',
	},
	[Flight.createProposalMarketSuggestion]: {
		display: 'Create Proposal Market Suggestion',
		description: 'Enables the Create Proposal Market Suggestion Link',
	},
	[Flight.requiredCredit]: {
		display: 'Required credit',
		description:
			'enable to make credit check mandatory for Publish/Submit, if set false credit will be optional.',
		needsPageRefreshToActivate: true,
	},
	[Flight.reviewAndPublishStyle]: {
		display: 'Review and Publlish Style',
		description:
			'enable to see new style changes to the review and publish page, if set false the style will remain previous version.',
	},
	[Flight.riConfigurationPanel]: {
		display: 'RI Configuration Panel',
		description: 'enable to see new RI Configuration Panel',
	},
	//#endregion
	//#region S
	[Flight.safeListedBd360Requestor]: {
		display: 'Safelisted DD360 Requestor',
		description: 'Overrides requestor email when creating a ticket to request Custom Terms',
		disableInEnvs: [AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.safelistedCreditCheck]: {
		display: 'Safelisted Credit Check',
		description: 'Credit check uses a safelisted organization',
		disableInEnvs: [AppEnvironment.Prod],
		needsPageRefreshToActivate: true,
	},
	[Flight.searchByMeterIdLongerTimeout]: {
		display: 'Search By Meter Id Longer Timeout',
		description: 'Increases axios timeout for Search By Meter Id',
	},
	[Flight.sequenceDownloadSharedMetersGql]: {
		display: 'Sequence Download Shared Meters GQL',
		description: 'Make sequential Download Shared Meters GQL calls',
	},
	[Flight.showDateIsInThePastErrorForSharedDiscount]: {
		display: 'Show Date is in the past error for expired shared discounts',
		description: 'Show Date is in the past error for expired shared discounts',
	},
	[Flight.simulateCreditCheckNull]: {
		display: 'Simulate credit check as null',
		description: 'Simulate credit check service response to null',
	},
	[Flight.simulateCreditCheckManualCancelled]: {
		display: 'Simulate credit check manual cancelled',
		description: 'Simulate credit check service response to manual cancelled',
	},
	[Flight.simulateCreditCheckManualRejected]: {
		display: 'Simulate credit check manual rejected',
		description: 'Simulate credit check service response to manual rejected',
	},
	[Flight.simulateCreditCheckManualReview]: {
		display: 'Simulate credit check manual review',
		description: 'Simulate credit check service response to manual review',
	},
	[Flight.simulateCreditCheckPendingNoCredit]: {
		display: 'Simulate credit check pending no credit',
		description: 'Simulate credit check service response to pending no credit',
	},
	[Flight.simulateCreditCheckPendingReview]: {
		display: 'Simulate credit check pending review',
		description: 'Simulate credit check service response to pending review',
	},
	[Flight.simulateTradeStatusNeedScreening]: {
		display: 'Simulate trade status need screening',
		description: 'Simulate tradestatus service response to need screening',
	},
	[Flight.ssc]: {
		display: 'Service specific credits',
		description:
			'Discoverability and configuration of service speficic credits (SSC) and contingent service specific credits (CSSC)',
		needsPageRefreshToActivate: true,
	},
	[Flight.softwareSubs]: {
		display: 'Software Subs',
		description: 'Enables Software Subscriptions',
		needsPageRefreshToActivate: true,
	},
	[Flight.swSubFutureDiscounts]: {
		display: 'Future Discounts for Software Subscriptions',
		description: 'Enables future discounts for Software Subscriptions',
	},
	[Flight.sscLegacy]: {
		display: 'Service specific credits - Legacy only',
		description:
			'This enables the ability to create Legacy proposal alone with service speficic credits (SSC) context',
		needsPageRefreshToActivate: true,
	},
	[Flight.subscriptionManagementFutureStartDate]: {
		display: 'Subscription Management Future Start Date',
		description: 'Enables future start date for subscription management',
	},
	[Flight.subscriptionWizard]: {
		display: 'Subscription Wizard',
		description: 'Enables Subscription Wizard experience',
	},
	//#endregion
	//#region T
	[Flight.tenantAdminDeprecation]: {
		display: 'CWA - Tenant Admin Deprecation',
		description: 'removes tenant admin call from authorized administrator',
	},
	[Flight.termsFinderSearch]: {
		display: 'Terms Finder Search',
		description: 'Allows searching of negotiated terms in the Finder',
	},
	[Flight.termsHarmonyConvertButton]: {
		display: 'Terms convert button using Harmony',
		description: 'Harmonize and keep isolated for conver to custom button',
	},
	[Flight.TestFlight1]: {
		display: 'Test Flight',
		description: 'Console log response from flight service',
	},
	[Flight.testHeader3YearsRetention]: {
		display: 'Extend Test header retention to 3 years',
		description: 'Allows to pass Test header retention to 3 years',
	},
	[Flight.testLargeQuoteDFDNONDFD]: {
		display: 'TestLargeQuoteDFDNONDFD',
		description: 'test flight for large quote DFD and NONDFD',
	},
	[Flight.throttleSearchByMeterId]: {
		display: 'Throttle Shared Meters Lookup',
		description: 'Throttle Shared Meters Product Lookup calls',
	},
	//#endregion
	//#region U

	[Flight.useBlobForCatalogCaching]: {
		display: 'Use blob for catalog caching',
		description: 'Fetch cached catalog data from storage account blob',
		needsPageRefreshToActivate: true,
	},
	[Flight.useNewEAAdminScope]: {
		display: 'Use 1PA Scope for EA Admin',
		description: 'Use 1PA Scope for EA Admin',
	},
	[Flight.useFluentPanelForAzurePlan]: {
		display: 'Use Fluent panel for Azure Plan',
		description: 'Use Fluent panel for Azure Plan config panel',
	},
	[Flight.useFluentPanelForACD]: {
		display: 'Use Fluent panel for ACD',
		description: 'Use Fluent panel for ACD config panel',
	},
	[Flight.useFluentPanelForDiscountConfig]: {
		display: 'Use Fluent panel for Discount config',
		description: 'Use Fluent panel for Discount configuration panel',
	},
	[Flight.useFluentPanelForMeters]: {
		display: 'Use Fluent panel for RI Configuration Panel',
		description: 'Use Fluent panel for RI Configuration Panel',
	},
	[Flight.useFluentPanelForModernOffice]: {
		display: 'Use Fluent panel for Modern Office',
		description: 'Use Fluent panel for Modern Office config panel',
	},
	[Flight.useFluentPanelForMonetary]: {
		display: 'Use Fluent panel for Monetary',
		description: 'Use Fluent panel for Monetary config panel',
	},
	[Flight.useNewScopeForBalanceManager]: {
		display: 'Use new scope for Balance Manager',
		description: 'Use new scope and PME authority to generate token for Balance Manager',
	},
	[Flight.usePMWCertForGraphAPI]: {
		display: 'Use PMW Cert for Graph API',
		description: 'Use PMW Cert for Graph API',
	},
	[Flight.useQuoteProgramCode]: {
		display: 'Use Quote ProgramCode',
		description:
			'Adds programCode to Quote on creation & uses agreementTemplate info from Quotes (Direct-Commercial only)',
		needsPageRefreshToActivate: true,
	},
	[Flight.useRawDateAndDurationValues]: {
		display: 'Use Raw Date and Duration Values',
		description:
			'Removes dependency on DateAndDuration type and implements values that are on the QS quote line item',
	},
	[Flight.use1PAFIC]: {
		display: 'Use FIC credential to acquire token from 1PA',
		description: 'Use FIC credential to acquire token from 1PA instead of certificate SN+I',
	},
	//#endregion
	//#region V
	[Flight.vanguard]: {
		display: 'Vanguard',
		description: 'Vanguard special framework',
	},
	[Flight.virusScanTestHeader]: {
		display: 'Virus Scan Test Header',
		description: 'Add test header to scan virus via MsDefender',
	},
};

export function getFlight(value: string): Flight | undefined {
	return flightList.find(flight => flight.toLowerCase() === value.toLowerCase());
}

export function isFlight(value: string): value is Flight {
	return !!getFlight(value);
}
